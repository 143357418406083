import React from "react";
import { Settings, PhotoAlbumOutlined, Dashboard } from "@material-ui/icons";
import { Roles } from "./components/Auth";

import { MenuRoute } from "./toolympus/components/frame";
import { SystemSubmenu } from "./toolympus/components/Contests/SystemSubmenu";
import { MediaLibContainer } from "./toolympus/components/medialib/MediaLibForm";
import { DashboardContainer } from "./components/Dashboard/DashboardContainer";
import { EditDashboardsContainer } from "./components/Dashboard/EditDashboardsContainer";

const InnerRoutes: MenuRoute[] = [
    { path: '/dashboards', title: 'Dashboards', component: EditDashboardsContainer, hidden: true },
    {
        path: '/dashboard',
        title: 'Dashboards',
        icon: <Dashboard />,
        component: DashboardContainer,
        alsoActivateForPath: (path: string) => path.startsWith('/dashboards')
    },
    {
        path: "/media",
        title: "Media lib",
        icon: <PhotoAlbumOutlined />,
        component: () => <MediaLibContainer apiPath="/api/media" spaceId="1" urlPrefix="/uploads" />,
    },
    { 
        path: '/system',
        title: 'System',
        icon: <Settings />,
        component: () => (
            <SystemSubmenu
                emails={{ apiPath: "/emails" }}
                users={{ userRoles: Roles, allowInvites: true, allowPaswordReset: true, allowPaswordChange: true, twoFactorAuthEnabled: true }}
                robud={{}}
                queryConsole={{}}
            />),
    },
];

export default InnerRoutes;