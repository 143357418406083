import { useEffect, useState } from "react";
import { useFetch } from "../../api/useFetch"
import { getWidgetLink } from "./links";
import { DashboardConfig, WidgetConfig } from "./types";
import { useWidgetDetails, WidgetDetailsData } from "./useWidgetDetails";

export const queryParams = (params: any) => '?' + Object.keys(params).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&');

export type UseDashboardType = 
    (apiPrefix: string, dashboardCode: string, getWidgetUrl: (id: string, params: any) => string) 
=> {
    loading: boolean,
    dashboard: null | DashboardConfig,
    params: any,
    widgetsParams: any,
    setParams: (v: any) => void,
    update: () => void,
    getWidgetUrl: (id: string, params: any) => string,
    openLink: (w: WidgetConfig, row: Record<string, any> | null) => boolean,
    details: WidgetDetailsData,
}

export const useDashboard: UseDashboardType = (apiPrefix, dashboardCode, getWidgetUrl) => {
    const {loading, data} = useFetch<null | DashboardConfig>(null, { url: `${apiPrefix}/${dashboardCode}` });

    const defaultParams = { dashboard: dashboardCode };
    const [params, setParams] = useState<Record<string, any>>(defaultParams);
    const [widgetsParams, setWidgetsParams] = useState<Record<string, any>>({ _v: 0 });

    // here we plug an incrementing _v param to force widgets reload on update()
    const updateWidgetsParams = (ps: Record<string, any>) => setWidgetsParams(wp => ({ ...ps, _v: (wp?._v || 0) + 1}))

    const details = useWidgetDetails(apiPrefix);

    useEffect(() => {
        if (data) {
            const params = data.params.reduce((prev, {code, default: def = ''}) => ({...prev, [code]: def}), defaultParams);
            setParams(params);
            updateWidgetsParams(params);
        } else {
            setParams(defaultParams);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const openLink = (w: WidgetConfig, row: Record<string, any> | null) => {
        let link = getWidgetLink(w, row);
        if(link) {
            if(link.startsWith('/')) {
                link = `${window.location.origin}${link}`;
            }
            const win = window.open(link, "_blank");
            win?.focus();
            return true;
        }
        return false;
    }

    return {
        loading, 
        dashboard: data,  
        params, 
        setParams,
        getWidgetUrl,
        widgetsParams,
        update: () => updateWidgetsParams(params),
        details,
        openLink,
    }
}