import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, withTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { MediaFile, MediaLib } from './useMediaLib';
import { FormGrid } from '../primitives/Forms';
import { InsertDriveFileOutlined } from '@material-ui/icons';
import { useCopyText } from '../primitives/CopyText';
import { useFormats } from '../schemed';
import { utc } from '../timezone';
import { useConfirmationDialog } from '../primitives/ConfirmationDialog';
import { openFileUploader } from '../../api/files';

interface Props {
    data: MediaLib;
    file: MediaFile | null;
    close: () => void;
}

const CheckeredColor = "#e0e0e0";

const ImagePreview = styled.img`
    width: 100%;
    height: auto;
    max-height: 50vh;
    object-fit: contain;
    background-image: linear-gradient(45deg, ${CheckeredColor} 25%, transparent 25%), linear-gradient(-45deg, ${CheckeredColor} 25%, transparent 25%), linear-gradient(45deg, transparent 75%, ${CheckeredColor} 75%), linear-gradient(-45deg, transparent 75%, ${CheckeredColor} 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    box-shadow: 0 0 5px 0 #00000020;
`;
const NonImagePreview = withTheme(styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background: ${props => props.theme.palette.grey[200]};
    border-radius: ${props => props.theme.shape.borderRadius}px;

    & > svg {
        color: #00000040;
        width: 200px;
        height: auto;
    }
`);

const Details = styled.div`
    & > * {
        width: 100%;
    }

    .actions {
        margin-top: 1rem;
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
    }
`;

export const MediaLibDetailsDialog = (props: Props) => {
    const { data, file, close } = props;
    const { getFilepath, downloadFile, replaceFile, removeFile } = data;
    const { formatDatetime } = useFormats();

    const copytext = useCopyText();
    const confirmations = useConfirmationDialog();
    const [cacheBuster, setCacheBuster] = useState<string>("");

    const url = file ? getFilepath(file) : "";

    return (
        <Dialog open={!!file} onClose={close} maxWidth="md" fullWidth>
            <DialogTitle><FormattedMessage id="medialib.browser.details" /></DialogTitle>
            {!!file && (
                <DialogContent>
                    <FormGrid columns="2fr 1fr">
                        {file.contenttype.startsWith("image")
                            ? <ImagePreview src={`${url}?${cacheBuster}`} alt={file.filename} />
                            : (<NonImagePreview>
                                <InsertDriveFileOutlined />
                                </NonImagePreview>)}

                        <Details>
                            <FormGrid columns="1fr">
                                <TextField
                                    disabled
                                    key="filename"
                                    value={file.filename}
                                    label={<FormattedMessage id="medialib.label.filename" />}
                                    onClick={() => copytext(file.filename)}
                                    />
                                <TextField
                                    disabled
                                    key="url"
                                    value={url}
                                    label={<FormattedMessage id="medialib.label.url" />}
                                    onClick={() => copytext(url)}
                                    />
                                <TextField
                                    disabled
                                    key="created_at"
                                    value={formatDatetime(utc.toLocal(file.created_at))}
                                    label={<FormattedMessage id="medialib.label.created_at" />}
                                    />
                            </FormGrid>

                            <div className="actions">
                                <Button
                                    key="download"
                                    color="primary"
                                    size="small"
                                    onClick={() => downloadFile(file)}>
                                    <FormattedMessage id="medialib.browser.download" />
                                </Button>
                                <Button
                                    key="replace"
                                    color="primary"
                                    size="small"
                                    onClick={() => openFileUploader(f => replaceFile(file, f).then(() => setCacheBuster(`${+ new Date().getTime()}`)))}>
                                    <FormattedMessage id="medialib.browser.replace" />
                                </Button>
                                <Button
                                    key="remove"
                                    color="secondary"
                                    size="small"
                                    onClick={() => {
                                        confirmations.open({
                                            execute: () => { removeFile(file); close(); },
                                            title: <FormattedMessage id="medialib.browser.remove_file_confirmation" />,
                                            confirmationLabel: <FormattedMessage id="common.delete" />,
                                    })}}>
                                    <FormattedMessage id="medialib.browser.remove" />
                                </Button>
                            </div>
                        </Details>
                    </FormGrid>
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={close}><FormattedMessage id="common.close" /></Button>
            </DialogActions>
        </Dialog>
    );
}
