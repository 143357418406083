import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { FieldType } from '../../hooks/useSchema';
import { WidgetConfig } from '../Dashboard/types';
import { ActionRow } from '../primitives/ActionRow';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { FormControl, TableForFields } from '../schemed';
import { WidgetList } from './useWidgetList';

interface Props {
    data: WidgetList;
    addWidget: (widget: WidgetConfig) => void;
}

export const WidgetListPopup = (props: Props) => {
    const { data: { widgets, isLoading, isOpen, close, filter, setFilter }, addWidget } = props;
    return (
        <Dialog open={isOpen} onClose={close} maxWidth="md" fullWidth >
            <DialogTitle><FormattedMessage id="dashboard.screen.widgets" /></DialogTitle>
            <DialogContent>
                <ActionRow>
                    <FormControl
                        field="filter"
                        schema={{ label_id: "searchModal.title", type: FieldType.text }}
                        row={{ filter }}
                        onChange={(_, c) => setFilter(c.filter)}
                        extraProps={{ controlProps: { autoFocus: true }}}
                    />
                    {isLoading && <LoadingIndicator />}
                </ActionRow>

                <TableForFields
                    data={widgets}
                    fields={[["title"], ["subtitle"], ["widgettype"]]}
                    schema={{
                        title: { type: FieldType.text, label_id: "dashboard.widget.title" },
                        subtitle: { type: FieldType.text, label_id: "dashboard.widget.subtitle" },
                        widgettype: { type: FieldType.text, label_id: "dashboard.screen.type" },
                    }}
                    rowButtons={row => (
                        <Button onClick={() => addWidget(row)} color="primary" variant="outlined">
                            <FormattedMessage id="common.add" />
                        </Button>)}
                 />
            </DialogContent>
        </Dialog>
    );
}
