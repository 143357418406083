import React, { useState, ReactNode } from 'react';
import { IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { OccupyFreeSpace } from './ActionRow';
import { Form, FormRow } from './Forms';
import { LoadingIndicator } from './LoadingIndicator';

import { ButtonOptionPicker } from './ButtonOptionPicker';
import { FieldDefinition, TableForFields, TableProps } from '../schemed';
import { SearchField } from './SearchField';
import { Schema } from '../../hooks/useSchema';
import { CrudListData } from '../../api/useSimpleCrud';
import { PagingControl } from './PagingControl';
import { PagedLoadedData } from '../../hooks/useLoadedData';
import { useWindowHotkey } from './hotkeys';

interface Props<T> {
    data: CrudListData<T>;
    schema: Schema;
    title: ReactNode;
    headerActions?: ReactNode;
    tabs?: [string, string][];
    fields: FieldDefinition[];
    linkFields: string[];
    getLinkValue: (item: T) => string;
    totalRecordsText?: string;
    rowButtons?: (row: T) => ReactNode;
    onRowClick?: (row: T) => void;
    searchAutoFocus?: boolean;
    searchAuto?: boolean;
    noNewItemHotkey?: boolean;
    fieldElement?: TableProps<T>["fieldElement"];
}

export const SimpleList = <T extends unknown>(props: Props<T>) => {
    const { data, schema, title, tabs, fields, linkFields, getLinkValue, totalRecordsText, rowButtons, onRowClick, searchAutoFocus } = props;

    const [textFilter, setTextFilter] = useState<string>("");

    useWindowHotkey("alt+n", () => props.noNewItemHotkey ? null : data.create());
    
    return (
        <Form title={title}
            headerItems={<>
                <IconButton color='primary' onClick={() => data.create()}><Add /></IconButton>
                {props.headerActions}
                <OccupyFreeSpace />
                {data.isLoading && <LoadingIndicator sizeVariant="m" />}
                <SearchField
                    filter={props.searchAuto ? data.filter : textFilter}
                    setFilter={props.searchAuto ? data.setFilter : setTextFilter}
                    doSearch={() => data.setFilter(textFilter)}
                    buttonLabel=" "
                    noButton={props.searchAuto}
                    autoFocus={searchAutoFocus}
                />
                {tabs && tabs.length > 0 && <ButtonOptionPicker
                    options={tabs}
                    selected={data.view}
                    setSelected={data.setView} />}
                    </>}>

            {!!(data as PagedLoadedData<T>).paging && <FormRow>
                <PagingControl data={(data as PagedLoadedData<T>)} totalRecordsText={totalRecordsText} />
            </FormRow>}

            <TableForFields
                fields={fields}
                fieldLink={field => linkFields.find(f => f === field) ?  getLinkValue : null}
                data={data.data}
                schema={schema}
                rowButtons={rowButtons}
                onRowClick={onRowClick}
                fieldElement={props.fieldElement}
                />
            
        </Form>
    );
}