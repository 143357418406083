import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { Moment } from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';
import { useFormats } from '.';
import { DateInternalFormat, DateTimeInternalFormat } from '../../api/datetimeUtil';
import { utc } from '../timezone';
import { expandProps, FormControlProps } from './FormControlProps';

export const DateTimeEdit = (props: FormControlProps) => {
    const { row, field, onChange, value, label, controlProps, error, extraProps } = expandProps(props);
    const intl = useIntl();
    const formats = useFormats();
    const { toLocal, toServer } = utc;

    const isConvert = !!extraProps?.utcToLocal;

    const toUser: (v: ParsableDate) => ParsableDate = isConvert ? (v => v ? toLocal(v) : v) : (v => v);
    const toInternal: (v: Moment) => Moment = isConvert ? (v => toServer(v)) : (v => v);
    
    return <KeyboardDateTimePicker
                variant="inline"
                format={formats.datetime}
                label={label}
                value={toUser(value || null)}
                error={error}
                invalidDateMessage={intl.formatMessage({ id: "common.invalid_date_format"})}
                ampm={false}
                {...controlProps}
                InputProps={{
                    ...(controlProps.InputProps || {}),
                    readOnly: controlProps.readOnly,
                }}
                onChange={(date) => {
                    const converted = date ? toInternal(date) : date;
                    const formatted = converted ? converted.format(DateTimeInternalFormat) : null;
                    onChange(row, {[field]: formatted })
                }}
                />;
}


export const DateEdit = (props: FormControlProps) => {
    const { row, field, onChange, value, label, controlProps, error } = expandProps(props);
    const intl = useIntl();
    const formats = useFormats();
    
    return <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format={formats.date}
                label={label}
                value={value || null}
                error={error}
                invalidDateMessage={intl.formatMessage({ id: "common.invalid_date_format"})}
                {...controlProps}
                InputProps={{
                    ...(controlProps.InputProps || {}),
                    readOnly: controlProps.readOnly,
                }}
                onChange={(date) => onChange(row, {[field]: date ? date.format(DateInternalFormat) : null})}
                />;
}
