import React from 'react'
import styled from '@emotion/styled';
import { Button, DialogActions, DialogContent } from '@material-ui/core'
import { useForm } from '../../hooks/useForm'
import { DialogButton } from '../primitives/DialogButton'
import { LoadingIndicator } from '../primitives/LoadingIndicator'
import { EditForm } from './EditForm'
import { useEditDash } from './useEditDash'
import { Form } from '../primitives/Forms'
import { OccupyFreeSpace } from '../primitives/ActionRow';
import { FormattedMessage } from 'react-intl'

const formAddData = {
    code: '',
    title: '',
}

const DashboardSelectorWrapper = styled.div`
    width: 500px;
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    gap: 10px;
`;


interface Props {
    data: ReturnType<typeof useEditDash>;
};

export const EditDashboards = ({data: { data, loading, add, selectForm }}: Props) => {
    const formAdd = useForm(formAddData, { required: ['code', 'title'] });

    return <Form title=""
            headerItems={loading || !data ?
                <LoadingIndicator />
                :
                (<>    
                    <DashboardSelectorWrapper>
                        {selectForm.select('selector', data, item => ({value: item.code, label: item.title}), { label: <FormattedMessage id="dashboard.screen.dash" /> })}
                    </DashboardSelectorWrapper>
                    <OccupyFreeSpace />

                    <DialogButton button={<Button color="primary"><FormattedMessage id="dashboard.screen.create" /></Button>}>{close => <>
                        <DialogContent>
                            {formAdd.input('code', { label: <FormattedMessage id="dashboard.screen.code" /> })}
                            {formAdd.input('title', { label: <FormattedMessage id="dashboard.screen.title" /> })}
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={!formAdd.valid} onClick={close(() => add(formAdd.state))}><FormattedMessage id="dashboard.screen.create" /></Button>
                        </DialogActions>
                    </>}
                    </DialogButton>
                </>) }>
                {selectForm.state.selector && <EditForm code={selectForm.state.selector} /> }
    </Form>;
}