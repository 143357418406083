import { InputLabel, MenuItem, Paper, FormControl, Input } from '@material-ui/core';
import React from 'react'
import Autosuggest from 'react-autosuggest'
import { AutoCompleteWrapp } from './Autocomplete.style';

const renderInputComponent = (label: string | number | JSX.Element) => (inputProps: any) => {
    const { inputRef = () => {}, ref, ...other } = inputProps;

    return (
        <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Input 
                autoComplete="off"
                fullWidth
                InputProps={{
                    inputRef: (node: any) => {
                      ref(node);
                      inputRef(node);
                    },
                  }}
                {...other}
            />
        </FormControl>
    )
}

const renderSuggestion = (suggestion: string, { isHighlighted }: Autosuggest.RenderSuggestionParams) => {
    return (
      <MenuItem selected={isHighlighted} component="div">
        {suggestion}
      </MenuItem>
    );
  }

interface Props {
    label: string | number | JSX.Element;
    value: string;
    onChange: (v: string) => void;
    suggestions: string[];
}

interface State {
    suggestions: string[]
}

export class Autocomplete extends React.Component<Props, State> {

    state = {
        suggestions: this.props.suggestions
    }

    getSuggestions = (value: string) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;
      
        return this.props.suggestions.filter(suggestion => {
              const keep = count < 5 && suggestion.slice(0, inputLength).toLowerCase() === inputValue;
      
              if (keep) {
                count += 1;
              }
      
              return keep;
            });
      }

      handleSuggestionsFetchRequested = ({ value }: {value: string}) => {
        this.setState({
          suggestions: this.getSuggestions(value),
        });
      };

    render() {
        return <AutoCompleteWrapp> 
                <Autosuggest 
                renderInputComponent={renderInputComponent(this.props.label)}
                renderSuggestion={renderSuggestion}
                getSuggestionValue={sug => sug}
                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                onSuggestionsClearRequested={() => this.setState({suggestions: []})}
                suggestions={this.state.suggestions}
                shouldRenderSuggestions={() => true}
                renderSuggestionsContainer={options => (
                    <Paper {...options.containerProps} square>
                      {options.children}
                    </Paper>
                )}
                inputProps={{
                    onChange: (_, {newValue}) => this.props.onChange(newValue),
                    value: this.props.value,

                }}
            />
        </AutoCompleteWrapp>
    }
}