import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#4a00e0',
        },
        secondary: {
            main: '#5beb8b',
        },
    },
});
