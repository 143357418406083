import { apiFetch } from "../../api/core";
import { useCrudItem } from "../../api/useSimpleCrud";
import { ProgramSchema } from "./schema";
import { Program, ProgramOperation } from "./types";
import { useProgramExecutions } from "./useProgramExecutions";
import { useTriggers } from "./useTriggers";

const useEditOperations = ({ data, update }: { data: Program, update: (c: Partial<Program>) => void }) => {
    const operations = data.operations || [];
    const addOperation = () => {
        update({ operations: [ ...operations, { kind: "sql", script: "" }]});
    }

    const updateOperation = (idx: number, changes: Partial<ProgramOperation>) => {
        if(changes.user_email !== null && changes.user_email !== undefined) {
            changes.user = { email: changes.user_email };
        }
        update({ operations: operations.map((op,i) => i === idx ? {
            ...op, ...changes,
        } : op)})
    }

    const removeOperation = (idx: number) => {
        update({ operations: operations.filter((op,i) => i !== idx) });
    }

    return {
        operations,
        addOperation,
        updateOperation,
        removeOperation,
    }
}

export const useProgram = (apiPath: string, id: string | number) => {
    const programApiPath = `${apiPath}/program`;
    const data = useCrudItem<Program>(`${programApiPath}/${id}`, {
        defaultValue: {} as Program,
    });

    const copyItem = () => {
        return apiFetch<Program>(
            `${programApiPath}`,
            "post",
            {
                title: `${data.data.title} (copy)`,
                data: data.data.data,
                operations: data.data.operations,
            });
    }

    const operations = useEditOperations(data);
    const triggers = useTriggers(programApiPath, data.data);
    const executions = useProgramExecutions(apiPath, data.data._id);

    return {
        ...data,
        schema: ProgramSchema,
        copyItem,
        operations,
        triggers,
        executions,
    }
}
