import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import { MediaFile, useMediaLibContext } from "../medialib";
import { Attachment, NotificationTask } from "./useNotificationTasks";

interface Config {
    task: Partial<NotificationTask> | null;
    update: (changes: Partial<NotificationTask>) => void;
}

export const useAttachments = ({ task, update: updateTask }: Config) => {
    const medialib = useMediaLibContext();
    const { enqueueSnackbar } = useSnackbar();
    const { formatMessage } = useIntl();

    const attachments = (task?.attachments || []);

    const add = (mf: MediaFile) => {
        if(!mf.fs_path) {
            enqueueSnackbar(
                formatMessage({ id: "notifications.task.controls.fs_path_not_configured" }),
                { variant: "error", autoHideDuration: 5000 }
                );
            return;
        }
        const attachment = { filename: mf.filename, filepath: mf.fs_path };
        updateTask({ attachments: [...attachments, attachment ]})
    }

    const remove = (idx: number) => {
        updateTask({ attachments: attachments.filter((_,i) => i !== idx) })
    }

    const update = (idx: number, changes: Partial<Attachment>) => {
        updateTask({ attachments: attachments.map((a,i) => i === idx ? { ...a, ...changes } : a) })
    }

    return {
        isAllowed: !!medialib.isAvailable,
        attachments,
        add,
        remove,
        update,
    }
}

export type AttachmentsData = ReturnType<typeof useAttachments>;
