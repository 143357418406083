import React from 'react';
import { Panel, PanelContainer } from './Number.style';

export type PlaceholderType = (props: { getColumnWidth?: (cols: number) => string }) => JSX.Element

export const Placeholder: PlaceholderType = ({ getColumnWidth = () => '100%' }) => {
    return (
        <PanelContainer style={{width: getColumnWidth(1)}}>
            <Panel elevation={0} />
        </PanelContainer>)
}