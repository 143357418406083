import styled from '@emotion/styled';
import { IconButton, Typography, withTheme } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import React from 'react';
import { useItemWithControls } from '../../api/useWithControls';
import { FormGrid } from '../primitives';
import { ProgramOperationSchema } from './schema';
import { ProgramOperation } from './types';

interface Props {
    idx: number;
    operation: ProgramOperation;
    update: (c: Partial<ProgramOperation>) => void;
    remove: () => void;
}

const Wrapper = withTheme(styled(FormGrid)`
    border-left: 2px solid ${props => props.theme.palette.primary.main};
    padding: 0 0 0.5rem 10px;
    margin-bottom: 1rem;
    align-items: baseline;

    & > :first-child {
        color: ${props => props.theme.palette.grey[400]};
    }
`);

export const OperationEditor = (props: Props) => {
    const { operation, update, remove, idx } = props;
    const { controls } = useItemWithControls({ data: operation, update: update }, {
        schema: ProgramOperationSchema,
    });
    return (
        <Wrapper columns="6px 1fr 1fr max-content" gap="dense">
            <Typography variant="caption">{idx+1}.</Typography>
            {controls([
                ["kind", { wrapperStyle: { gridColumn: "2 / span 2" }}]
            ])}
            <IconButton size="small" onClick={remove}><DeleteOutline /></IconButton>
            {controls([
                operation.kind === "sql" && ["script", { autoRows: true, wrapperStyle: { gridColumn: "2 / span 3" }}],
                operation.kind === "email" && ["template", { wrapperStyle: { gridColumn: "2 / span 1" }}],
                operation.kind === "email" && ["user_email"],
            ])}
            <div />
        </Wrapper>
    );
}
