import { useCallback } from 'react';
import { scaleOrdinal } from '@visx/scale';
import { schemePaired } from 'd3-scale-chromatic';
import { ChartRow } from './util';


export const useColorScheme = (data: ChartRow[]) => {
    const n = Math.max(data.length, 2);

    const colors = [...schemePaired];

    const getColor = useCallback(
        scaleOrdinal({
            domain: data.map(r => r.label),
            range: colors,
          }),
          [n]);

    return {
        getColor,
        getColorUnscaled: (n: number) => colors[Math.floor(n / colors.length)],
        singleColor: colors[1],
        axisColor: "#33333340",
        gridColor: "#33333310",
        axisLabelColor: "#333333",
    }
}
