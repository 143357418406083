import React, { useState } from 'react';
import styled from '@emotion/styled';
import { 
    Box,
    Button,
    Card,
    CardContent,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Radio,
    Select,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core';
import { Select as SelectTooly } from '../../components/schemed/Select'
import { Add, Autorenew, Delete } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

import { useForm } from '../../hooks/useForm';
import { FieldType, useSchema } from '../../hooks/useSchema';
import { WidgetConfig } from '../Dashboard/types';
import { TableForFields } from '../schemed';
import { ActionRow, OccupyFreeSpace } from '../primitives/ActionRow';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { Widget } from '../Dashboard/Widget';
import { getWidgetUrl } from '../Dashboard/DashboardPage';
import { getWidgetColumnsWidth } from '../Dashboard/Dashboard';
import { FormGrid } from '../primitives/Forms';
import isHotkey from 'is-hotkey';

const emptyVarForGettingFieldsTyles: WidgetConfig = null as any;
type ResFieldType = typeof emptyVarForGettingFieldsTyles.result_fields[0];

interface ResultFieldFormProps {
    field: ResFieldType,
    onChange: (f: ResFieldType) => void,
    onDelete: () => void,
}

const ResultFieldForm = ({ field, onChange, onDelete }: ResultFieldFormProps) => {
    const schemas: any = useSchema();

    const availableEntities = Object.keys(schemas);
    const isJustValue = !field?.entity;

    const change = (key: keyof ResFieldType, value: any) => {
        onChange({
            ...field, 
            [key]: value,
        })
    }

    return <Box padding="5px">
            <Card>
                <Box
                    display="grid" 
                    gridTemplateColumns="1fr 1fr max-content" 
                    gridGap="10px" 
                    padding="10px"
                    marginBottom="5px"
                >
                    <TextField 
                        label={<FormattedMessage id="dashboard.widget.name" />}
                        value={field.name}
                        onChange={e => change('name', e.target.value)}
                    />
                    <TextField 
                        label={<FormattedMessage id="dashboard.widget.label" />}
                        value={field.label}
                        onChange={e => change('label', e.target.value)}
                    />
                    <IconButton onClick={onDelete}>
                        <Delete />
                    </IconButton>

                    <Box gridColumn="1/4" padding="10px">
                        <FormControlLabel 
                            onClick={() => {
                                if(!isJustValue) {
                                    onChange({ ...field, entity: undefined, entity_field: undefined });
                                }
                            }}
                            control={<Radio checked={isJustValue}/>} 
                            label={<FormattedMessage id="dashboard.widget.select_res_field.fieldtype" />} 
                        />

                        <FormControlLabel 
                            onClick={() => { 
                                if(isJustValue && availableEntities.length) {
                                    onChange({ ...field, fieldtype: undefined, entity: availableEntities[0] });
                                }
                            }}
                            control={<Radio checked={!isJustValue}/>} 
                            label={<FormattedMessage id="dashboard.widget.select_res_field.entity" />} 
                        />

                        {isJustValue ?
                            <FormControl fullWidth>
                                <InputLabel><FormattedMessage id="dashboard.widget.select_res_field.fieldtype_label" /></InputLabel>
                                <Select value={field.fieldtype} onChange={e => change('fieldtype', e.target.value)}>
                                    <MenuItem value="number"><FormattedMessage id="dashboard.widget.select_res_field.number" /></MenuItem>
                                    <MenuItem value="text"><FormattedMessage id="dashboard.widget.select_res_field.text" /></MenuItem>
                                    <MenuItem value="percent"><FormattedMessage id="dashboard.widget.select_res_field.percent" /></MenuItem>
                                </Select>
                            </FormControl>
                            : 
                            <>
                                <FormControl fullWidth>
                                    <InputLabel><FormattedMessage id="dashboard.widget.select_res_field.entity_label" /></InputLabel>
                                    <Select value={field.entity} onChange={e => change('entity', e.target.value)}>
                                        {availableEntities.map(key => 
                                            <MenuItem key={key} value={key}>{key}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                                {field.entity &&
                                    <FormControl fullWidth>
                                        <InputLabel><FormattedMessage id="dashboard.widget.select_res_field.entity_field" /></InputLabel>
                                        <Select value={field.entity_field || ''} onChange={e => change('entity_field', e.target.value)}>
                                            {Object.keys(schemas[field.entity]).map(key => {
                                                const schema = schemas[field.entity as string][key];
                                                const label = schema && schema.label ? `${schema.label} (${key})` : key;
                                                return { key, label }
                                            })
                                            .sort((a,b) => a.label > b.label ? 1 : -1)
                                            .map(({ key, label }) => <MenuItem key={key} value={key}>{label || key}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                }
                            </>
                        }
                    </Box>
                </Box>
            </Card>
        </Box>
    
}

const DisplaySettingsGrid = styled.div`
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 36px;

    & > div {
        display: flex;
        flex-flow: column;
    }
`;

const DisplaySettingsEdit = ({ form }: { form: { state: WidgetConfig, change: (field: keyof WidgetConfig, updated: WidgetConfig['display_settings']) => void }}) => {
    return (
        <Card elevation={0}>
            <CardContent>
                <Typography variant="h6">
                    <FormattedMessage id="dashboard.widget.displaySettings" /> 
                </Typography>
                <DisplaySettingsGrid>
                    <div>
                        <FormControlLabel 
                            control={
                                <Switch 
                                    checked={form.state.display_settings.show_pct} 
                                    onClick={(e: any) => 
                                        form.change(
                                            'display_settings', 
                                            { ...form.state.display_settings, show_pct: e.target.checked }
                                        )}
                                />
                            }
                            label={<FormattedMessage id="dashboard.widget.show_pct" />}
                            
                        />

                        <FormControlLabel 
                            control={
                                <Switch 
                                    checked={form.state.display_settings.show_table} 
                                    onClick={(e: any) => 
                                        form.change(
                                            'display_settings', 
                                            { ...form.state.display_settings, show_table: e.target.checked }
                                            )}
                                            />
                                        }
                                        label={<FormattedMessage id="dashboard.widget.show_table" />} 
                                        />

                        <FormControlLabel 
                            control={
                                <Switch 
                                    checked={!form.state.display_settings.hide_chart} 
                                    onClick={(e: any) => 
                                        form.change(
                                            'display_settings', 
                                            { ...form.state.display_settings, hide_chart: !e.target.checked }
                                        )}
                                />
                            }
                            label={<FormattedMessage id="dashboard.widget.show_chart" />} 
                        />
                        
                        <SelectTooly
                            schema={{
                                type: FieldType.select,
                                label_id: "dashboard.widget.total_row",
                                values: [{ value: "sum", label: "SUM"}, { value: "avg", label: "AVG"}],
                            }}
                            field="total_row"
                            row={form.state.display_settings}
                            onChange={(o,c) => form.change('display_settings', { ...form.state.display_settings, total_row: c.total_row })}
                            />
                            
                    </div>
                    <div>
                        {form.state.widgettype !== "counter" && form.state.widgettype !== "placeholder" && <TextField
                            margin="dense"
                            value={form.state.display_settings.empty_state_message}
                            onChange={e => form.change(
                                'display_settings', 
                                { ...form.state.display_settings, empty_state_message: e.target.value }
                            )}
                            label={<FormattedMessage id="dashboard.widget.empty_state_message" />}
                            helperText={<FormattedMessage id="dashboard.widget.empty_state_message_hint" />}
                            />}
                        <TextField
                            margin="dense"
                            value={form.state.display_settings.link}
                            multiline
                            onChange={e => form.change(
                                'display_settings', 
                                { ...form.state.display_settings, link: e.target.value }
                            )}
                            label={<FormattedMessage id="dashboard.widget.links_by_labels" />}
                            helperText={<FormattedMessage id="dashboard.widget.links_by_labels_hint" />}
                            />
                        {form.state.widgettype === "counter" && <TextField
                            margin="dense"
                            value={form.state.display_settings.counter_colors}
                            multiline
                            onChange={e => form.change(
                                'display_settings', 
                                { ...form.state.display_settings, counter_colors: e.target.value }
                            )}
                            label={<FormattedMessage id="dashboard.widget.counter_colors" />}
                            helperText={<FormattedMessage id="dashboard.widget.counter_colors_hint" />}
                            />}
                    </div>
                </DisplaySettingsGrid>
            </CardContent>
        </Card>
    );
}

const DetailsResultFieldForm = ({ field, onChange, onDelete }: ResultFieldFormProps) => {

    const change = (key: keyof ResFieldType, value: any) => {
        onChange({
            ...field, 
            [key]: value,
            fieldtype: "text",
        })
    }

    return <Box padding="5px">
            <Card>
                <Box
                    display="grid" 
                    gridTemplateColumns="1fr 1fr 1fr max-content" 
                    gridGap="10px" 
                    padding="10px"
                    marginBottom="5px"
                >
                    <TextField 
                        label={<FormattedMessage id="dashboard.widget.name" />}
                        value={field.name}
                        onChange={e => change('name', e.target.value)}
                    />
                    <TextField 
                        label={<FormattedMessage id="dashboard.widget.label" />}
                        value={field.label}
                        onChange={e => change('label', e.target.value)}
                    />
                    <TextField 
                        label={<FormattedMessage id="dashboard.widget.link" />}
                        value={field.link}
                        onChange={e => change('link', e.target.value)}
                    />
                    <IconButton onClick={onDelete}>
                        <Delete />
                    </IconButton>
                </Box>
            </Card>
        </Box>
    
} 

interface EditWidgetPopupProps {
    dashboardCode: string;
    widget: WidgetConfig;
    onSave: (data: WidgetConfig) => void;
    onRemove: () => void;
    onDuplicate: () => void;
    isLoading: boolean;
}

let idcount = 0;

export const SqlInputProps = {
    autoRows: true,
    rows: 10,
    controlProps: { style: { fontFamily: 'monospace' }, spellCheck: false },
};

export const EditWidgetPopup = ({ widget, onRemove, onSave, onDuplicate, isLoading, dashboardCode }: EditWidgetPopupProps) => {
    const [widgetState] = useState({ 
        ...widget, 
        result_fields: widget.result_fields.map(field => ({...field, itemId: ++idcount})),
        details_fields: widget.details_fields.map(field => ({...field, itemId: ++idcount})),
        params: widget.params.map(param => ({...param, itemId: ++idcount}))
    });

    const [testParams, setTestParams] = useState<Record<string, string>>({});
    const [testUrl, setTestUrl] = useState<string>("");

    const form = useForm(widgetState);

    const { result_fields: res_fields, details_fields } = form.state;

    const saveWidget = () => {
        onSave({
            ...form.state, 
            result_fields: form.state.result_fields.map(({itemId, ...field}) => field),
            details_fields: form.state.details_fields.map(({itemId, ...field}) => field),
            params: form.state.params.map(({itemId, ...param}) => param)
        });
    }

    return <>
        <DialogContent
            onKeyUp={e => {
                if(isHotkey("alt+s", e) || isHotkey("mod+s", e)) {
                    e.preventDefault();
                    saveWidget();
                }
            }}>
            <ActionRow>
                <Typography variant="h5"><FormattedMessage id="dashboard.widget.dialog" /></Typography>
                <OccupyFreeSpace />
                {isLoading && <LoadingIndicator sizeVariant="s" />}
                <Button onClick={onDuplicate}><FormattedMessage id="dashboard.widget.duplicate" /></Button>
            </ActionRow>

            <Card elevation={0}>
                <CardContent>
                    <Box 
                        display="grid" 
                        gridTemplateColumns="1fr 1fr 1fr"
                        gridGap="10px"
                    >
                        {form.input('title', { label: <FormattedMessage id="dashboard.widget.title" /> })}
                        {form.input('subtitle', { label: <FormattedMessage id="dashboard.widget.subtitle" /> })}
                        {form.select(
                            'widgettype', 
                            [
                                {value: 'pie', label: <FormattedMessage id="dashboard.screen.pie" />} as any,
                                {value: 'bar', label: <FormattedMessage id="dashboard.screen.bar" />} as any,
                                {value: 'counter', label: <FormattedMessage id="dashboard.screen.counter" />} as any,
                                {value: 'placeholder', label: <FormattedMessage id="dashboard.screen.placeholder" />} as any,
                            ],
                            item => item,
                            { label: <FormattedMessage id="dashboard.screen.widgettype" /> }
                        )}
                    </Box>
                </CardContent>
            </Card>

            <DisplaySettingsEdit form={form} />

            <Card elevation={0}>
                <CardContent>
                    <Typography variant="h6">
                        <FormattedMessage id="dashboard.widget.resultfields" /> 
                        <IconButton onClick={() => form.change('result_fields', [...res_fields, { name: '', label: '', itemId: ++idcount }])}><Add /></IconButton>
                    </Typography>
                    {res_fields.map((field) => 
                        <ResultFieldForm 
                            key={field.itemId}
                            field={field} 
                            onChange={changed => form.change('result_fields', res_fields.map((f) => f.itemId === field.itemId ? changed : f))}
                            onDelete={() => form.change('result_fields', res_fields.filter((f) => f.itemId !== field.itemId))}
                        />
                    )}
                </CardContent>
            </Card>

            <Card elevation={0}>
                <CardContent>
                    <Typography variant="h6">
                        <FormattedMessage id="dashboard.widget.params" /> 
                        <IconButton 
                            onClick={() => form.change(
                                'params', 
                                [
                                    { label: '', code: '', fieldtype: 'text', default: '', itemId: ++idcount }, 
                                    ...form.state.params,
                                ]
                            )}
                        >
                            <Add />
                        </IconButton>
                    </Typography>
                    <TableForFields
                        schema={{
                            label: {
                                type: FieldType.text,
                                label: <FormattedMessage id="dashboard.widget.param.label" />
                            },
                            code: {
                                type: FieldType.text,
                                label: <FormattedMessage id="dashboard.widget.param.code" />
                            },
                            fieldtype: {
                                type: FieldType.select,
                                label: <FormattedMessage id="dashboard.widget.param.fieldtype" />,
                                values: [
                                    { value: FieldType.text, label: <FormattedMessage id="dashboard.widget.param.fieldtypes.text" /> as any },
                                    { value: FieldType.number, label: <FormattedMessage id="dashboard.widget.param.fieldtypes.number" /> as any },
                                ]
                            },
                        }} 
                        fields={[
                            ['label', { editable: true }],
                            ['code', { editable: true }],
                            ['fieldtype', { editable: true }],
                            ['remove'],
                        ]}
                        data={form.state.params}
                        onChange={(old, changes) => form.change(
                            'params',
                            form.state.params.map(param => old.itemId === param.itemId? {...param, ...changes} : param)
                        )}
                        fieldElement={field => 
                            field === 'remove'?
                                (row) => 
                                <IconButton 
                                    onClick={() =>  form.change(
                                        'params',
                                        form.state.params.filter(param => row.itemId !== param.itemId)
                                    )}
                                >
                                    <Delete />
                                </IconButton>
                                :
                                null
                        }
                    />
                </CardContent>
            </Card>


            <Card elevation={0}>
                <CardContent>
                    <Typography variant="h6">
                        <FormattedMessage id="dashboard.widget.query" /> 
                    </Typography>
                    {form.select(
                        'value_field', 
                        form.state.result_fields, 
                        field => ({value: field.name, label: field.label}),
                        { label: <FormattedMessage id="dashboard.widget.value_field" /> }
                    )}

                    {form.select(
                        'label_field', 
                        form.state.result_fields, 
                        field => ({value: field.name, label: field.label}),
                        { label: <FormattedMessage id="dashboard.widget.label_field" /> }
                    )}

                    {form.input('query_substitutions', {
                        ...SqlInputProps,
                        label: <FormattedMessage id="dashboard.widget.query_substitutions" />,
                        hint: <FormattedMessage id="dashboard.widget.query_substitutions_hint" />,
                        })}

                    {form.input('query_string', { ...SqlInputProps, label: <FormattedMessage id="dashboard.widget.query_string" /> })}

                    {form.input('details_query_string', { ...SqlInputProps, label: <FormattedMessage id="dashboard.widget.details_query_string" /> })}
                </CardContent>
            </Card>

            <Card elevation={0}>
                <CardContent>
                    <Typography variant="h6">
                        <FormattedMessage id="dashboard.widget.detailsresultfields" /> 
                        <IconButton onClick={() => form.change('details_fields', [...details_fields, { name: '', label: '', itemId: ++idcount }])}><Add /></IconButton>
                    </Typography>
                    {details_fields.map((field) => 
                        <DetailsResultFieldForm 
                            key={field.itemId}
                            field={field} 
                            onChange={changed => form.change('details_fields', details_fields.map((f) => f.itemId === field.itemId ? changed : f))}
                            onDelete={() => form.change('details_fields', details_fields.filter((f) => f.itemId !== field.itemId))}
                        />
                    )}
                </CardContent>
            </Card>

            <Card elevation={0}>
                <CardContent>
                    <FormGrid key="test-title" columns="1fr max-content">
                        <Typography variant="h6">
                            <FormattedMessage id="dashboard.widget.test_query" /> 
                        </Typography>

                        
                    </FormGrid>

                    <FormGrid key="test-params" noMargin>
                        {form.state.params.map(p => (
                            <TextField
                                key={p.code}
                                value={testParams[p.code] || ""}
                                label={p.code}
                                onChange={e => { const v = e.target.value; setTestParams(ps => ({ ...ps, [p.code]: v }))}}
                                />
                        ))}
                        <Button
                            color="primary"
                            endIcon={<Autorenew />}
                            onClick={() => {
                                const updatedParams = { ...testParams, dashboard: dashboardCode, v: (+(testParams["v"] || 0) + 1).toString()};
                                setTestParams(updatedParams);
                                setTestUrl(getWidgetUrl(widget._id, updatedParams));
                            }}
                            />
                    </FormGrid>

                    {testUrl && <Widget
                        config={widget}
                        getColumnWidth={getWidgetColumnsWidth}
                        url={testUrl}
                        openDetails={() => {}}
                        />}
                </CardContent>
            </Card>



        </DialogContent>
        <DialogActions>
            <Button onClick={onRemove} color="secondary" disabled={isLoading}>
                <FormattedMessage id="dashboard.widget.remove" />
            </Button>
            <Button onClick={saveWidget} variant="contained" color="primary" disabled={isLoading}>
                <FormattedMessage id="dashboard.screen.save" />
            </Button>
        </DialogActions>
    </>
}