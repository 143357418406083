import { Card } from "@material-ui/core";
import styled from '@emotion/styled';

export const PanelContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
`;

export const Panel = styled(Card)<{ color?: string }>`
    width: 100%;
    height: 100%;
    
    color: white;
    font-style: normal;
    text-decoration: none;
    text-decoration-color: transparent;
    background: ${props => props.color
        ? `linear-gradient(30deg, ${props.color}80 -10%, ${props.color} 30%)`
        : "transparent"};

    &:hover {
        background: ${props => props.color
        ? props.color
        : "transparent"};
    }
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    
    border: 2px solid transparent;
    padding: 10px;
`;

export const PanelLink = styled.a`
    cursor: pointer;
    transition: 150ms;
    &:hover {
        border-color: ${props => props.theme.palette.primary.main};
    }
`;

export const SmallText = styled.div`
    font-size: 16px;
    text-align: center;
`;

export const LargeText = styled.div`
    font-size: 36px;
    text-align: center;
`;
