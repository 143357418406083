import styled from '@emotion/styled';
import { withTheme } from '@material-ui/core';

export const WidgetsWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
`;

export const ParamsForm = withTheme(styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    padding: 10px;
    margin-bottom: 1rem;
    border-bottom: 1px solid ${props => props.theme.palette.grey[300]}};
`);

export const FormField = styled.div`
    width: 100%;
    padding: 3px;
`;
