import styled from "@emotion/styled";

export const AutoCompleteWrapp = styled.div`
    & .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    & .react-autosuggest__container {
        position: relative;
    }

    & .react-autosuggest__suggestions-container--open {
        position: absolute;
        z-index: +999;
        margin-top: 3px;
        left: 0;
        right: 0;
    }
`;
