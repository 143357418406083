import React, { useState } from 'react';
import {
    useHistory,
} from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText, withTheme } from '@material-ui/core';
import InnerRoutes from './InnerRoutes';
import { clearApiToken, decodeToken, getApiToken } from './toolympus/api/core';
import { AppWrapper, Sidebar, Menu, Routes, getRoutesToRender, AppContentSimple, DrawerPaperView } from './toolympus/components/frame/new';
import { DefaultUser, UserContext } from './toolympus/userContext/UserContext';
import { ExitToApp } from '@material-ui/icons';
import { useLoginGuard } from './toolympus/hooks/auth/useLoginGuard';
import { Logo } from './components/Common';
import styled from '@emotion/styled';

const SidebarPaper = withTheme(styled(DrawerPaperView)`
    & .MuiDrawer-paper{
        color: ${props => props.theme.palette.primary.main};
        background-color: ${props => props.theme.palette.primary.contrastText};
    }
    
    & .MuiSvgIcon-root {
        color: ${props => props.theme.palette.primary.main};
    }

`);


const AppRouter: React.FC = () => {
    const token = getApiToken();
    const history = useHistory();
    const [user, setUser] = useState<DefaultUser>(token ? decodeToken(token) as DefaultUser : { _id: '', roles: [], email: '' });

    const logout = () => {
        clearApiToken();
        history.push('/login');
    }

    useLoginGuard("/login", { isAuthenticated: !!user && user.roles.length > 0 });

    const { routes, menuItems } = getRoutesToRender(InnerRoutes, user);

    const bottom = (
        <List>
            <ListItem button key='logout' onClick={logout}>
                <ListItemIcon><ExitToApp /></ListItemIcon>
                <ListItemText primary='Sign out' />
            </ListItem>
        </List>
    )

    return (
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
            <AppWrapper>
                <Sidebar
                    top={<Logo />}
                    menu={<Menu menuItems={menuItems} />}
                    bottom={bottom}
                    open={true}
                    onClose={() => { }}
                    drawerPaperComponent={SidebarPaper}
                    drawerPaperProps={{ shadow: true }}
                />
                <AppContentSimple fit100Height>
                    <Routes routes={routes} notFoundRedirect="/system" />
                </AppContentSimple>
            </AppWrapper>
        </UserContext.Provider>
    );
}

export default AppRouter;

