import { makeStyles, withTheme } from "@material-ui/core";
import styled from "@emotion/styled";

export const useStyles = makeStyles(theme => ({
    widgetsGrid: {
        display: 'flex',
        flexFlow: 'row wrap',
        userSelect: 'none',
    },
    autoHeight: {
        display: 'grid',
    },
    gutter: {
        padding: 15
    },
    shadow: {
        opacity: .5,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        "& .MuiSvgIcon-root": {
            fontSize: '14px',
            marginRight: '10px'
        },

        "& div:last-child .MuiSvgIcon-root": {
            fontSize: '14px',
            margin: '0'
        }
    },
    arrow: {
        display: 'flex',
        alignItems: 'center'
    },
    moveIcon: {
        cursor: 'grab',

        '&:active': {
            cursor: 'grabbing',
        }
    },
    arrowIcon: {
        cursor: 'pointer'
    }
}));


export const ParametersPane = withTheme(styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid ${props => props.theme.palette.grey[300]};

    & .section-title {
        cursor: pointer;
        .MuiButtonBase-root {
            margin-left: 0.5rem;
        }
    }
`);
